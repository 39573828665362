import Header from "../../component/header"
import LoginButton from "../../component/loginbutton"

const Index = () => {
  return(
    <div className="flex flex-col mx-auto items-center w-96 gap-2">
      <Header />
      <LoginButton />
      <Content />
    </div>
  )
}

export default Index

const Content = () => {
  return (
    <div className="flex justify-evenly">
      <JPdescription />
      {/* <Endescription /> */}
    </div>
  )
}

const JPdescription = () => {
  return(
    <div className = "p-4">
      <h2 className="text-lg font-sans py-2">
        twfileはシンプルなファイル共有サービスです
      </h2>
      <div className="text-sm">
        ログインして相手を選ぶだけで簡単にファイルが共有出来ます
      </div>
    </div>
  )
}

const Endescription = () => {
  return(
    <div className = "p-4">
      <h2>
        twfile is a simple file sharing service
      </h2>
      <div>
        Simply login and select the person you want to share files with.
      </div>
    </div>
  )
}