
const Header = () => {
  return(
    <header className="flex flex-col w-full">
      <h2 className="py-12 self-center">
        twfile
      </h2>
      <nav className="flex justify-around">
        <a
          href='/'
          className="rounded-lg hover:bg-slate-100 py-2 px-4">
          TOP
        </a>
        <a
          href='/upload'
          className="rounded-lg hover:bg-slate-100 py-2 px-4">
          UPLOAD
        </a>
      </nav>
    </header>
  )
}

export default Header