import { useState } from "react"
import { AiOutlineLink, AiFillMail } from "react-icons/ai";
import toast, { Toaster } from 'react-hot-toast';
import Header from "../../component/header";


const Upload = () => {
  const [selectedfile,setSelectedFile] = useState()
  const [loading,setLoading] = useState()
  const [error,setError] = useState()
  const [url,setURL]=useState()
  const [type,setType]=useState()
  const [screenname,setScreenname]=useState()
  const [formColor,setFormColor] = useState('')
  const [accpetColor,setAcceptColor] = useState('')
  const [filesize,setFilesize] = useState(0)

  const handleClick = async() => {
    if(!type){
      return setAcceptColor('bg-red-200')
    }
    if(type==='screenname' && !screenname){
      return setAcceptColor('bg-red-200')
    }
    if(!selectedfile){
      return setFormColor('bg-red-200')
    }
    const formData = new FormData()
    formData.append('file',selectedfile)
    setLoading(true)
    const query = screenname || type
    fetch('/api/upload?accept='+query,
    {
      method:'POST',
      body:formData,
    })
      .then(async res => {
      setLoading(false)
      if(res.status !== 200)return setError(res.status)
      const r = await res.json()
      setError(null)
      return setURL(r.url)
    })
  }

  const handleChange = async(file) => {
    setFilesize(file.size.toLocaleString())
    if(file.size > 100000000){
      setFormColor('bg-red-200')
      return
    }
    setFormColor('')
    return setSelectedFile(file)
  }

  const handleAccpetChange = async(d) => {
    setType(d)
    setAcceptColor('')
  }

  const copyToClipboard = async () => {
    toast('URL Copied.')
    await global.navigator.clipboard.writeText(url);
  };

  return(
    <div className="flex flex-col mx-auto w-96 gap-2">
      <Header />
      <form className={formColor + ' py-6 flex flex-col rounded-xl hover:bg-slate-100'}>
        <div className="p-2">
          Select File
        </div>
        <div className="p-2">
          {filesize}/ 100,000,000byte
        </div>
        <input
          type="file"
          name="file"
          className="px-2"
          required
          onChange={(e) => handleChange(e.target.files[0])}
        />
      </form>
      <div className={accpetColor + ' py-6 flex flex-col gap-1 rounded-xl hover:bg-slate-100'}>
        <div className="p-2">
          Select Scope
        </div>
        <div className="px-2">
          <button className="bg-blue-700 text-white hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded text-sm text-center items-center h-10 w-32" onClick={() => handleAccpetChange('follower')}>
            follower
          </button>
        </div>
        <div className="flex px-2">
          <button className="bg-blue-700 text-white hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded text-sm text-center items-center h-10 w-32" onClick={() => handleAccpetChange('screenname')}>
            screenname
          </button>
          { type === 'screenname'
          &&  <div className="flex flex-col px-2">
                <input type='text' placeholder='e.g. @elonmask' className="my-auto h-10 w-32 rounded p-2" onChange={(e) => setScreenname(e.target.value)} />
              </div>
          }
        </div>

      </div>
      <div className="p-2">
        { type === 'screenname'
        ?<>Send to : {screenname} </>
        :<> { type
            ? <>Send to : {type}</>
            : <div> </div>
            }
        </>}
      </div>
      { error
        && <ErrorCol error={error}></ErrorCol>
      }
      <div className="p-2">
      {
        loading
          ? <Loading />
          : url
            ? <Share click={() => copyToClipboard()} url={url} />
            : <UploadButton click={() => handleClick()}/>
      }
      </div>
      <Toaster />
    </div>
  )
}

const Share = (props) => {
  const selfDM = (url) => {
    console.log(url)
  }
  return(
    <div className="h-12 flex flex-col">
      <div className="h-12 flex">
        <div className='border-spacing-4 border rounded h-12 w-12'>
          <AiOutlineLink className="w-full h-full" onClick={() => props.click()}/>
        </div>
        <div className='border-spacing-4 border rounded h-12 w-12'>
          <AiFillMail className="w-full h-full" onClick={() => selfDM(props.url)}/>
        </div>
      </div>
      <div className="p-2 text-xs">
        {props.url}
      </div>
    </div>
  )
}

const ErrorCol = (props) => {
  return (
  <div className="py-2 bg-red-700 text-white font-medium rounded text-sm text-center items-center">
    {props.error}
  </div>
  )
}

const UploadButton = (props) => {
  return (
  <button onClick={() => props.click()} type="button" className="bg-blue-700 text-white hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded text-sm text-center items-center h-10 w-32">
    Upload
  </button>
  )
}

const Loading = () =>{
  return(
    <div className="flex justify-center h-10 w-32 items-center">
      <div className="animate-ping h-4 w-4 bg-blue-600 rounded-full"></div>
    </div>
  )
}



export default Upload