import { useEffect,useState } from 'react';
import { useParams } from 'react-router-dom';
import Header from '../../component/header';
import LoginButton from '../../component/loginbutton';


const Download = () => {
  const { id } = useParams();
  return(
    <div className="flex flex-col mx-auto items-center w-96 gap-2">
      <Header />
      <Content id={id}/>
    </div>
  )
}

const Content = (props) => {
  const id = props.id
  const [data,setData] = useState()
  const [error,setError] = useState()
  useEffect(() => {
    fetch('/api/dl/' + id)
    .then(async res => {
      const r = await res.json()
      if(res.status === 200) return setData(r)
      console.log(r)
      return setError(r)
    })
  },[id])
  return (
  <>
  { data
    ? <div className='flex flex-col gap-2'>
        <div>
          Owner:@{data.screen_name}
        </div>
        <div>
          Scope:{data.accept}
        </div>
        <div>
          <DLbutton id={id} />
        </div>
      </div>
    : error
      ? <ErrorComponent id={id}/>
      : <>loading</>
  }
  </>
  )
}

const ErrorComponent = (props) => {
  return (
  <>
    <LoginButton id={props.id}/>
    <div>need login</div>
  </>
  )
}

const DLbutton = (props) => {
  return (
  <a
      download
      href={"/api/download/"+props.id}
      className="flex justify-center bg-blue-700 text-white hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded text-sm text-center items-center h-10 w-40">

      <span>
        Download
      </span>
    </a>
  )
}

export default Download