import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { HelmetProvider } from 'react-helmet-async'
import Index from './pages/index'
import Download from './pages/download';
import Upload from './pages/upload';

if (process.env.NODE_ENV === 'development') {
  const { worker } = require('./mocks/browser')
  worker.start()
}

const router = createBrowserRouter([
  {
    path:'upload',
    element:<Upload />,
    errorElement:<>error</>,
  },{
    path:'download/:id',
    element:<Download /> ,
    errorElement:<>error</>,
  },{
    path:'/',
    element:<Index />,
    errorElement:<>error</>,
  }])

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <RouterProvider router={router}/>
    </HelmetProvider>
  </React.StrictMode>
);

reportWebVitals();
